import React from 'react';
import { Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Head from '../components/head/head';
import NavBar, { NavBarSpacer } from '../components/nav-bar/navBar';
import Footer from '../components/footer/footer';
import BodyCopy from '../components/body-copy/bodyCopy';

const Home = (): JSX.Element => {
  return (
    <Container fluid>
      <Head />
      <NavBar />
      <NavBarSpacer />
      <BodyCopy>
        <article>
          <h1>Driver Privacy Statement (Australia)</h1>
          <div>Last Updated: August 1st 2020</div>
          <div>
            HOP collects information about you when you use our mobile
            applications, websites, and other online products and services
            (collectively, the “Services”) and through other interactions and
            communications you have with us. For persons residing in Australia,
            the Services are provided by HOP Technologies Pty Ltd, and this
            Privacy Statement (“Statement”) applies to information collected and
            used by HOP Technologies Pty Ltd (hereinafter “HOP” or “we”).
          </div>
          <h1>1. Scope and Application</h1>
          <div>
            This Statement applies to drivers, couriers, partner transportation
            companies, and any other persons that use the HOP platform under
            license (collectively “Drivers,” or individually “you’) and reside
            in Australia. This Statement does not apply to Drivers who reside
            outside Australia. The Statement also does not apply to persons
            (e.g. riders) who use our app or Services to request transportation,
            delivery, or other on-demand services (“Users”). If you interact
            with the Services as both a User and a Driver, the respective
            privacy statements apply to your different interactions.
          </div>
          <h1>2. Collection of Information</h1>
          <h2>Information You Provide to Us</h2>
          <div>
            We collect information you provide directly to us, such as when you
            create, modify, or log in to your account, request support from us,
            consent to a background check, submit your driver’s license, submit
            information about your vehicle or insurance, or otherwise
            communicate with us. This information may include; name, email,
            phone number, postal address, profile picture, payment information,
            vehicle registration information, insurance information, driver’s
            license information, and other information you may choose to
            provide.
          </div>
          <div>
            Information We Collect Through Your Use of Our Services When you use
            our Services, we collect information about you in the following
            general categories:
          </div>
          <div>
            Location Information: As a condition of providing Users with
            transportation, delivery, or other services via the HOP platform,
            your precise location data must be provided to HOP via the app that
            you use. Once the HOP app has permission to access location services
            through the permission system used by your mobile operating system
            (“platform”), we will collect the precise location of your device
            when the app is running in the foreground or background. We may also
            derive your approximate location from your IP address.
          </div>
          <div>
            Contacts Information: If you permit the HOP app to access the
            address book on your device via the permission system used by your
            platform, we may access and store names and contact information from
            your address book to facilitate social interactions through our
            Services and for other purposes described in this Statement or at
            the time of consent or collection.
          </div>
          <div>
            Transaction Information: We collect transaction details related to
            the transportation (or other) services you provide through our
            platform, including the type of service provided, date and time the
            service was provided, amount charged, distance travelled, and other
            related transaction details. Additionally, if someone uses your
            promo code, we may associate your name with that person.
          </div>
          <div>
            Usage and Preference Information: We collect information about how
            you and site visitors interact with our Services, preferences
            expressed, and settings chosen. In some cases we do this through the
            use of cookies, pixel tags, and similar device identification
            technologies that create and maintain unique identifiers.
          </div>
          <div>
            Device Information: We may collect information about your mobile
            device, including, for example, the hardware model, operating system
            and version, software and file names and versions, preferred
            language, unique device identifier, advertising identifiers, serial
            number, device motion information, and mobile network information.
          </div>
          <div>
            Call and SMS Data: Our Services facilitate communications between
            Users and Drivers. In connection with facilitating this service, we
            receive call data, including the date and time of the call or SMS
            message, the parties’ phone numbers, and the content of the SMS
            message.
          </div>
          <div>
            Log Information: When you interact with the Services, we collect
            server logs, which may include information like device IP address,
            access dates and times, app features or pages viewed, app crashes
            and other system activity, type of browser, and the third- party
            site or service you were using before interacting with our Services.
          </div>
          <div>
            We also collect, use and share Aggregated Data such as statistical
            or demographic data for any purpose. Aggregated Data could be
            derived from your personal data but is not considered personal data
            in law as this data will not directly or indirectly reveal your
            identity. For example, we may aggregate your Usage Data to calculate
            the percentage of users accessing a specific website feature.
            However, if we combine or connect Aggregated Data with your personal
            data so that it can directly or indirectly identify you, we treat
            the combined data as personal data which will be used in
          </div>
          <div>
            accordance with this Privacy Notice. e We do not collect any special
            categories of personal data about you (this includes details about
            your race or ethnicity, religious or philosophical beliefs, sex
            life, sexual orientation, political opinions, trade union
            membership, information about your health, and genetic and biometric
            data).
          </div>
          <div>
            We do collect personal data that is to do with driving-related
            offences and criminal convictions that our drivers have received
            before they are fully able to become members with us. We only obtain
            and process this data when we have a lawful basis in which to do so
            (i.e. consent has been given to us by the driver for us to obtain
            this data).
          </div>
          <div>
            Our products and services are not intended for children and
            therefore, we do notknowingly collect data relating to children.
          </div>
          <h2>Important Information About Platform Permissions</h2>
          <div>
            Most mobile platforms (GOS, Android, etc.) have defined certain
            types of device data that apps cannot access without your consent.
            And these platforms have different permission systems for obtaining
            your consent. The iOS platform will alert you the first time the HOP
            app wants permission to access certain types of data and will let
            you consent (or not consent) to that request. Android devices will
            notify you of the permissions that the HOP app seeks before you
            first use the app, and your use of the app constitutes your consent.
          </div>
          <h2>Information We Collect From Other Sources</h2>
          <div>
            We may also receive information from other sources and combine that
            with information we collect through our Services. For example:
          </div>
          <div>
            If you choose to link, create, or log in to your HOP account with a
            payment provider (e.g., Stripe) or social media service (e.g.,
            Facebook), or if you engage with a separate app or website that uses
            our API (or whose API we use), we may receive information about you
            or your connections from that site or app.
          </div>
          <div>
            If you use the Services through an account associated with a partner
            transportation company, we may receive information about you from
            that partner company.
          </div>
          <div>
            When you provide on demand services to Users, our Users may provide
            us with a driver rating or similar rating of the service you provide
            for them.
          </div>
          <div>
            If you also interact with our Services in another capacity, for
            instance to request on demand services as a User, or as a user of
            other apps we may provide, we may combine or associate that
            information with information we have collected from you in your
            capacity as a Driver. 3. Use of Information
          </div>
          <h2>We may use the information we collect about you to:</h2>
          <div>
            Provide, maintain, and improve our Services, including, for example,
            to facilitate payments, send receipts, provide products and services
            (and send related information), develop new features, provide
            customer support to Users and Drivers, develop safety features,
            authenticate users, and send product updates and administrative
            messages; Perform internal operations, including, for example, to
            prevent fraud and abuse of our Services; to troubleshoot software
            bugs and operational problems; to conduct data analysis, testing,
            and research; and to monitor and analyse usage and activity trends;
            Send or facilitate communications (i) between you and a User (e.g. a
            rider), such as estimated times of arrival (ETAs), or (ii) between
            you and a contact of yours at your direction in connection with your
            use of certain features, such as referrals.
          </div>
          <div>
            Send you communications we think will be of interest to you,
            including information about products, services, promotions, news,
            and events of HOP and other companies, where permissible and
            according to local applicable laws; and to process contest,
            sweepstake, or other promotion entries and fulfil any related
            awards;
          </div>
          <div>
            Personalise and improve the Services, including to provide or
            recommend features, content, social connections, referrals, and
            advertisements.
          </div>
          <div>
            We may transfer the information described in this Statement to, and
            process and store it in other countries, some of which may have less
            protective data protection laws than the region in which you reside.
            Where this is the case, we will take appropriate measures to protect
            your personal information in accordance with this Statement.
          </div>
          <h1>4. Sharing of Information</h1>
          <div>
            We may share the information we collect about you as described in
            this Statement or as described at the time of collection or sharing,
            including as follows:
          </div>
          <h2>Through Our Services we may share your information:</h2>
          <div>
            With Users in connection with their request for transportation or
            other services. For instance we may share your name, personal photo,
            vehicle photo, vehicle license, vehicle make and model, precise or
            approximate location, average rating provided by Users, and certain
            contact information (depending upon your location and applicable
            laws). After you have provided the requested services, we will send
            the User a receipt via SMS or other means which may include the
            breakdown of amounts charged, your name, contact information, photo,
            a map of the route you took, and other transaction details.
          </div>
          <div>
            With other people, as directed by you, in connection with a
            particular service, offering or promotion; or with third parties to
            provide you a service you requested through a partnership or
            promotional offering made by a third party or us; e With the general
            public if you submit content in a public forum, such as blog
            comments, social media posts, or other features of our Services that
            are viewable by the general public;
          </div>
          <div>
            With third parties with whom you choose to let us share information,
            for example other apps or websites that integrate with our API or
            Services, or those with an API or Service with which we integrate;
            and
          </div>
          <div>
            With your partner transportation company and any necessary third
            parties engaged by us or the partner company, if you use the
            Services through an account connected to a partner transportation
            company.
          </div>
          <h2>Other Important Sharing We may share your information:</h2>
          <div>
            With HOP subsidiaries and affiliated entities that provide services
            or conduct data processing on our behalf, or for data centralisation
            and / or logistics purposes;
          </div>
          <div>
            With vendors, consultants, marketing partners, and other service
            providers who need access to such information to carry out work on
            our behalf;
          </div>
          <div>
            Inresponse to a request for information by a competent authority if
            we believe disclosure is in accordance with, or is otherwise
            required by, any applicable law, regulation, ordinance, license,
            operating agreement, or legal process;
          </div>
          <div>
            With law enforcement officials, government authorities, or other
            third parties if we believe your actions are inconsistent with our
            user agreements or policies, or to protect the rights, property, or
            safety of HOP or others;
          </div>
          <div>
            Inconnection with, or during negotiations of, any merger, sale of
            company assets, consolidation or restructuring, financing, or
            acquisition of all or a portion of our business by or into another
            company;
          </div>
          <div>
            If we otherwise notify you and you consent to the sharing; and
          </div>
          <div>
            Inan aggregated and/or anonymised form which cannot reasonably be
            used to identify you.
          </div>
          <h1>5. Social Sharing Features</h1>
          <div>
            The Services may integrate with social sharing features and other
            related tools which let you share actions you take on our Services
            with other apps, sites, or media, and vice versa. Your use of such
            features enables the sharing of information with your friends or the
            public, depending on the settings you establish with the social
            sharing service. Please refer to the privacy policies of those
            social sharing services for more information about how they handle
            the data you provide to or share through them. 6. Your Choices
          </div>
          <h2>Account Information</h2>
          <div>
            You may correct your account information at any time by logging into
            your online or in-app account. If you wish to cancel your account,
            please email us at support@myhop.io. Please note that in some cases
            we may retain certain information about you as required by law, or
            for legitimate business purposes to the extent permitted by law. For
            instance, if you have a standing credit or debt on your account, or
            if we believe you have committed fraud or violated our Terms, we may
            seek to resolve the issue before deleting your information.
          </div>
          <h2>Access Rights</h2>
          <div>
            HOP will comply with individual’s requests regarding access,
            correction, and/ or deletion of the personal data it stores in
            accordance with applicable law.
          </div>
          <h2>Location Information</h2>
          <div>
            We request permission for our app’s collection of precise location
            from your device per the permission system used by your mobile
            operating system. If you initially permit the collection of this
            information, you can later disable it by changing the location
            settings on your mobile device. However, you will not be able to
            provide services on the HOP platform if you disable our collection
            of precise location data. Additionally, disabling our collection of
            precise location from your device will not limit our ability to
            derive approximate location from your IP address.
          </div>
          <h2>Contact Information</h2>
          <div>
            We may also seek permission for our app’s collection and syncing of
            contact information from your device per the permission system used
            by your mobile operating system. If you initially permit the
            collection of this information, iOS users can later disable it by
            changing the contacts settings on your mobile device. The Android
            platform does not provide such a setting.
          </div>
          <h2>Promotional Communications</h2>
          <div>
            You may opt out of receiving promotional messages from us by
            following the instructions in those messages. If you opt out, we may
            still send you non- promotional communications, such as those about
            your account, about Services you have requested, or our ongoing
            business relations.
          </div>
          <h1>7. Changes to the Statement</h1>
          <div>
            We may change this Statement from time to time. If we make
            significant changes in the way we treat your personal information,
            or to the Statement, we will provide you notice through the Services
            or by some other means, such as email. Your continued use of the
            Services after such notice constitutes your consent to the changes.
            We encourage you to periodically review this Statement for the
            latest information on our privacy practices.
          </div>
          <h1>8. Failure to Provide Personal Data</h1>
          <div>
            Where we need to collect personal data by law or under the terms of
            a contract we have with you and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            goods and/or services). In this case, we may have to cancel goods
          </div>
          <div>
            and/or services you have with us - but we will notify you in
            advance.
          </div>
          <div>9. Failure to Provide Personal Data</div>
          <div>
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
            <div>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other
            </div>
            <div>
              means, and the applicable legal, regulatory, tax, accounting or
              other requirements.
            </div>
            <div>
              By law we have to keep basic information about our customers
              (including Contact, Identity, Financial and Transaction Data) for
              6 years after they cease being customers for tax purposes.
            </div>
            <div>
              In some circumstances you can ask us to delete your data by
              emailing
            </div>
            <div>
              support@myhop.io In some circumstances we will anonymise your
              personal data (so that it can no longer be associated with you)
              for research or statistical purposes, in which case we may use
            </div>
            <div>
              this information indefinitely without further notice to you.
            </div>
            <h1>10. Contact Us</h1>
            <div>
              If you have any questions about this Statement, please contact us
              at privacy@myhop.io
            </div>
          </div>
        </article>
      </BodyCopy>
      <Footer />
    </Container>
  );
};

export default Home;
